var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Alert } from "bootstrap";
import selectorsMap from "@constants/selectors-map";
import * as Alerter from "@constants/useAlert-data";
const useAlert = (message, options) => {
  let alertObject = {
    instance: null,
    element: null,
    show: () => false,
    hide: () => false,
    dispose: () => false,
    title: () => false,
    message: () => false,
    remove: () => false
  };
  const alertElement = cloneAlertElement(options);
  if (alertElement) {
    const alertElementHeader = insertAlertTitle(alertElement, options == null ? void 0 : options.title);
    const alertElementBody = insertAlertMessage(alertElement, message);
    const alertInstance = new Alert(alertElement);
    alertObject = {
      instance: alertInstance,
      element: alertElement,
      show: () => {
        if (alertElement.isConnected) {
          alertElement.classList.add("show");
          return true;
        }
        return false;
      },
      hide: () => {
        if (alertElement.isConnected) {
          alertElement.classList.remove("show");
          return true;
        }
        return false;
      },
      dispose: () => {
        if (alertElement.isConnected) {
          alertInstance.dispose();
          return true;
        }
        return false;
      },
      title: (markup) => {
        if (alertElementHeader == null ? void 0 : alertElementHeader.isConnected) {
          if (markup) {
            alertElementHeader.innerHTML = markup;
          }
          return alertElementHeader.innerHTML;
        }
        return false;
      },
      message: (markup) => {
        if (alertElementBody == null ? void 0 : alertElementBody.isConnected) {
          if (markup) {
            alertElementBody.innerHTML = markup;
          }
          return alertElementBody.innerHTML;
        }
        return false;
      },
      remove: () => {
        if (alertElement.isConnected) {
          alertElement.remove();
          return true;
        }
        return false;
      }
    };
  }
  return alertObject;
};
const cloneAlertElement = (options) => {
  var _a;
  const alertOptions = __spreadValues(__spreadValues({}, Alerter.Default), options);
  const selector = (_a = alertOptions.selector) != null ? _a : selectorsMap.alert.selector;
  const alertSelectorElement = document.querySelector(selector);
  if (alertSelectorElement) {
    const dummyElement = document.createElement("div");
    dummyElement.innerHTML = Alerter.Template;
    const alertElement = dummyElement.querySelector(selectorsMap.alert.alert);
    if (alertElement) {
      alertElement.classList.add(Alerter.Theme[alertOptions.type]);
      const alertElementIcon = alertElement.querySelector(selectorsMap.alert.icon);
      if (alertElementIcon) {
        if (alertOptions.title === void 0) {
          const codepoint = alertOptions.icon ? alertOptions.icon : Alerter.Codepoint[alertOptions.type];
          alertElementIcon.innerHTML = `&#x${codepoint};`;
        } else {
          alertElement.classList.add("flex-wrap");
          alertElementIcon.classList.add("d-none");
        }
      }
      if (alertOptions.dismissible === false) {
        const alertElementCloseBtn = alertElement.querySelector(selectorsMap.alert.close);
        alertElementCloseBtn == null ? void 0 : alertElementCloseBtn.classList.add("d-none");
      }
      if (alertOptions.classlist) {
        alertOptions.classlist.split(" ").forEach((value) => {
          if (value) {
            alertElement.classList.add(value);
          }
        });
      }
      alertSelectorElement.appendChild(alertElement);
    }
    return alertElement;
  }
  console.error("The selector for alert is not valid: %c%o", "color: white", selector);
  return null;
};
const insertAlertTitle = (alertElement, title) => {
  if (title) {
    const alertHeader = alertElement.querySelector(selectorsMap.alert.heading);
    if (alertHeader) {
      alertHeader.innerHTML = title;
      alertHeader.classList.remove("d-none");
      return alertHeader;
    }
  }
  return null;
};
const insertAlertMessage = (alertElement, message) => {
  const alertBody = alertElement.querySelector(selectorsMap.alert.body);
  if (alertBody) {
    alertBody.innerHTML = message;
  }
  return alertBody;
};
export default useAlert;
