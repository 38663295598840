import SelectorsMap from "@constants/selectors-map";
const initCustomer = () => {
  const { returnFormMainCheckbox, returnFormItemCheckbox } = SelectorsMap.order;
  const returnTableMainCheckbox = document.querySelector(returnFormMainCheckbox);
  returnTableMainCheckbox == null ? void 0 : returnTableMainCheckbox.addEventListener("click", () => {
    const checked = !!(returnTableMainCheckbox == null ? void 0 : returnTableMainCheckbox.checked);
    const itemCheckbox = document.querySelectorAll(returnFormItemCheckbox);
    itemCheckbox.forEach((checkbox) => {
      checkbox.checked = checked;
    });
  });
};
export default initCustomer;
