const initCurrencySelector = () => {
  const { Theme } = window;
  const { currencySelector: CurrencySelectorMap } = Theme.selectors;
  const currencySelector = document.querySelector(CurrencySelectorMap.currencySelector);
  currencySelector == null ? void 0 : currencySelector.addEventListener("change", (event) => {
    const option = event.target;
    window.location.href = option.value;
  });
};
export default initCurrencySelector;
