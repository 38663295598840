import { Modal } from "bootstrap";
import { isHTMLElement } from "@helpers/typeguards";
const { prestashop } = window;
prestashop.blockcart = prestashop.blockcart || {};
prestashop.blockcart.showModal = (html) => {
  var _a;
  const { Theme } = window;
  const { events } = Theme;
  const { blockcart: BlockcartMap } = Theme.selectors;
  function getBlockCartModal() {
    const blockCartModal2 = document.querySelector(BlockcartMap.modal);
    return blockCartModal2;
  }
  let blockCartModal = getBlockCartModal();
  if (blockCartModal) {
    blockCartModal.remove();
  }
  const mainElement = document.createElement("div");
  mainElement.innerHTML = html;
  const modalTemplate = mainElement.querySelector(BlockcartMap.modal);
  if (modalTemplate) {
    (_a = document.querySelector("body")) == null ? void 0 : _a.append(modalTemplate);
  }
  blockCartModal = getBlockCartModal();
  if (blockCartModal) {
    const modal = new Modal(blockCartModal);
    modal.show();
    blockCartModal.addEventListener("hidden.bs.modal", (event) => {
      const target = event.currentTarget;
      if (isHTMLElement(target)) {
        prestashop.emit(events.updateProduct, {
          reason: target.dataset,
          event
        });
      }
    });
  }
};
