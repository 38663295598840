var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Toast } from "bootstrap";
import selectorsMap from "@constants/selectors-map";
import * as Toaster from "@constants/useToast-data";
const useToast = (message, options) => {
  let toastObject = {
    instance: null,
    element: null,
    content: null,
    show: () => false,
    hide: () => false,
    dispose: () => false,
    message: () => false,
    remove: () => false
  };
  const toastElement = getToastElement(options == null ? void 0 : options.template);
  if (toastElement) {
    insertToastMessage(toastElement, message);
    const clonedOptions = __spreadValues(__spreadValues({}, Toaster.Default), options);
    addToastClassList(toastElement, clonedOptions);
    if (clonedOptions.autohide === false) {
      setCloseButtonVisible(toastElement);
    }
    const toastElementBody = toastElement.querySelector(selectorsMap.toast.body);
    if (toastElementBody) {
      const toastInstance = new Toast(toastElement, { autohide: clonedOptions.autohide, delay: clonedOptions.delay });
      toastObject = {
        instance: toastInstance,
        element: toastElement,
        content: toastElementBody,
        show: () => {
          if (toastElement.isConnected) {
            toastInstance.show();
            return true;
          }
          return false;
        },
        hide: () => {
          if (toastElement.isConnected) {
            toastInstance.hide();
            return true;
          }
          return false;
        },
        dispose: () => {
          if (toastElement.isConnected) {
            toastInstance.dispose();
            return true;
          }
          return false;
        },
        message: (markup) => {
          if (toastElement.isConnected) {
            if (markup) {
              toastElementBody.innerHTML = markup;
            }
            return toastElementBody.innerHTML;
          }
          return false;
        },
        remove: () => {
          if (toastElement.isConnected) {
            toastElement.remove();
            return true;
          }
          return false;
        }
      };
    }
  }
  return toastObject;
};
const getToastElement = (template) => {
  const toastContainer = document.querySelector(selectorsMap.toast.container);
  if (toastContainer) {
    return template === void 0 ? cloneToastTemplate(toastContainer) : appendToastTemplate(toastContainer, template);
  }
  return useFallbackToastContainer(template);
};
const cloneToastTemplate = (toastContainer, fallback = true) => {
  const toastTemplate = toastContainer.querySelector(selectorsMap.toast.template);
  const toastClone = toastTemplate == null ? void 0 : toastTemplate.content.cloneNode(true);
  const toastElement = toastClone == null ? void 0 : toastClone.querySelector(selectorsMap.toast.toast);
  const toastBody = toastElement == null ? void 0 : toastElement.querySelector(selectorsMap.toast.body);
  if (toastElement && toastBody) {
    toastContainer.appendChild(toastElement);
    return toastElement;
  }
  if (fallback) {
    const fallbackContainer = getFallbackContainer();
    if (fallbackContainer) {
      toastContainer.innerHTML = fallbackContainer.innerHTML;
      return cloneToastTemplate(toastContainer, false);
    }
  }
  printConsoleError("Failed to clone toast template.", "Check the toast markup in theme or JS fallback.");
  return null;
};
const appendToastTemplate = (toastContainer, template) => {
  const dummyElement = document.createElement("div");
  dummyElement.innerHTML = template;
  const toastElement = dummyElement == null ? void 0 : dummyElement.querySelector(selectorsMap.toast.toast);
  const toastBody = toastElement == null ? void 0 : toastElement.querySelector(selectorsMap.toast.body);
  if (toastElement && toastBody) {
    toastContainer.appendChild(toastElement);
    return toastElement;
  }
  printConsoleError("The override toast template is not valid.", "Reference: https://getbootstrap.com/docs/5.0/components/toasts/");
  return null;
};
const useFallbackToastContainer = (template) => {
  const body = document.querySelector("body");
  const fallbackContainer = getFallbackContainer();
  if (body && fallbackContainer) {
    const toastElement = template === void 0 ? cloneToastTemplate(fallbackContainer, false) : appendToastTemplate(fallbackContainer, template);
    if (toastElement) {
      fallbackContainer.appendChild(toastElement);
      body.appendChild(fallbackContainer);
      return toastElement;
    }
  }
  return null;
};
const getFallbackContainer = () => {
  const dummyElement = document.createElement("div");
  dummyElement.innerHTML = Toaster.Fallback;
  const fallbackContainer = dummyElement.querySelector(selectorsMap.toast.container);
  return fallbackContainer;
};
const insertToastMessage = (toastElement, message) => {
  const toastBody = toastElement.querySelector(selectorsMap.toast.body);
  if (toastBody) {
    toastBody.innerHTML = message;
  }
};
const addToastClassList = (toastElement, options) => {
  let bsClassList = Toaster.Theme[options.type];
  const customClassList = options.classlist;
  if (customClassList) {
    bsClassList = bsClassList.concat(" ", customClassList.trim());
  }
  bsClassList.split(" ").forEach((value) => {
    if (value) {
      toastElement.classList.add(value);
    }
  });
};
const setCloseButtonVisible = (toastElement) => {
  var _a;
  const closeButton = toastElement.querySelector(selectorsMap.toast.close);
  if (closeButton) {
    let toastColor = (_a = toastElement.classList.toString().match(/text-\w+/)) == null ? void 0 : _a.toString();
    if (toastColor) {
      toastColor = toastColor.substring(toastColor.indexOf("-") + 1);
      if (toastColor === "white" || toastColor === "light") {
        closeButton.classList.add("btn-close-white");
      }
    }
    closeButton.classList.remove("d-none");
  }
};
const printConsoleError = (error, info) => {
  if (info) {
    console.group("useToast");
    console.error(error);
    console.info(info);
    console.groupEnd();
  } else {
    console.error(error);
  }
};
export default useToast;
