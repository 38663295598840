import { Collapse } from "bootstrap";
import { isHTMLElement } from "@helpers/typeguards";
export default () => {
  const { Theme } = window;
  const voucherCodes = document.querySelectorAll(Theme.selectors.cart.discountCode);
  voucherCodes.forEach((voucher) => {
    voucher.addEventListener("click", (event) => {
      event.stopPropagation();
      if (isHTMLElement(event.currentTarget)) {
        const code = event.currentTarget;
        const discountInput = document.querySelector(Theme.selectors.cart.discountName);
        const promoCode = document.querySelector(Theme.selectors.cart.promoCode);
        if (promoCode && discountInput) {
          const formCollapser = new Collapse(promoCode);
          discountInput.value = code.innerText;
          formCollapser.show();
        }
      }
      return false;
    });
  });
};
