var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
export const searchProduct = (url, value, resultsPerPage = 10) => __async(this, null, function* () {
  const formData = new FormData();
  formData.append("s", value);
  formData.append("resultsPerPage", resultsPerPage.toString());
  const datas = yield fetch(url, {
    method: "POST",
    body: formData,
    headers: {
      Accept: "application/json, text/javascript, */*; q=0.01"
    }
  });
  const jsonDatas = yield datas.json();
  return jsonDatas.products;
});
