const debounce = (callback, wait) => {
  let timeoutId;
  return (...args) => {
    if (typeof timeoutId !== "undefined") {
      window.clearTimeout(timeoutId);
    }
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
};
export default debounce;
